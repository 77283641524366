import { request } from '@/utils/request.js'

// 删除星球伙伴
export function delPartner(company, id) {
    return request.delete(`/company/${company}/partners/${id}`)
}

// 获取伙伴列表
export function getPartnerList(id, params) {
    return request.get(`/company/${id}/partners`, { params })
}

// 申请成为星球伙伴
export function joinPartner(company, id) {
    return request.post(`/company/${company}/partners/${id}`)
}

// 确认星球伙伴
export function confirmPartner(company, id, data) {
    return request.put(`/company/${company}/partners/${id}`, data)
}

// 获取星球伙伴信息
export function getPartner(company, id) {
    return request.get(`/company/${company}/partners/${id}`)
}